<template>
  <div class="callback-container" />
</template>

<script>
  import { callback } from '@/utils/social'

  export default {
    name: 'Callback',
    data() {
      return {
        loading: null,
      }
    },
    created() {
      this.loading = this.$baseLoading()
      callback()
      window.close()
    },
    beforeDestroy() {
      this.loading.close()
    },
  }
</script>
